<div class="explore-competitions-cards">
  <div
    class="card flex-xl-row p-xl-3"
    *ngFor="let competition of competitions | sortCompetitions"
  >
    <figure class="card-figure flex-shrink-0 m-0">
      <img
        class="img-fluid d-block"
        [ngSrc]="
          competition.thumbnail
            ? imageBaseURL + 'common/getfile/' + competition.thumbnail
            : '../../../../assets/images/student-portal/competition-img.png'
        "
        [width]="320"
        [height]="180"
        [alt]="competition?.name ?? 'Competition Image'"
      />
    </figure>
    <div
      class="card-body d-flex flex-column gap-2 px-3 py-1 pb-3 pb-xl-0 mt-3 mt-xl-0"
    >
      <!-- Registration Status -->
      <span
        class="competition-status"
        [ngClass]="{
          'upcoming': competition?.isUpcoming,
          'finished': competition?.status == 'Finished',
          'late-registration': competition?.registration_stage === 'Late',
          'priority-registration': competition?.registration_stage === 'Priority',
          'regular-registration': competition?.registration_stage === 'Regular',
          'closed-registration': competition?.registration_stage === 'Closed',
        }"
      >
        <span
          class="d-flex align-items-center me-1"
          *ngIf="competition?.status == 'Registration'"
        >
          <img
            [src]="registrationStageImages[competition?.registration_stage]"
            [alt]="
              competition?.registration_stage
                ? competition.registration_stage + ' Timer'
                : ''
            "
          />
        </span>

        <span class="text-center me-1">
          {{
            competition?.isUpcoming
              ? "Coming Soon"
              : competition?.status != "Registration"
              ? competition?.status
              : competition?.registration_stage === "Closed"
              ? "Registration Closed"
              : competition?.registration_stage +
                " " +
                "Registration" +
                " ends in"
          }}</span
        >
        <app-countdown-timer
          [endTime]="competition?.status_time_update"
          [isUpcomingCompetition]="
            competition?.status == 'Registration' ? false : true
          "
          [isTimerSimpleView]="true"
        >
        </app-countdown-timer>
      </span>
      <!-- Registration Status -->

      <!-- Competition Name -->
      <h6 class="card-title text-break" [title]="competition?.name">
        {{ competition?.name }}
      </h6>
      <!-- End: Competition Name -->

      <!-- Info -->
      <div class="d-flex gap-2 align-items-center">
        <img
          src="../../../assets/images/explore-page/subject.png"
          class="img-fluid"
          alt="subject"
        />
        <span class="text-break">{{ competition?.subject ? competition?.subject : "N/A" }}</span>
      </div>
      <div class="d-flex gap-2 align-items-center">
        <img
          src="../../../assets/images/explore-page/category.png"
          class="img-fluid"
          alt="category"
        />
        <span class="text-break">{{ competition?.category ? competition?.category : "N/A" }}</span>
        <span class="dot-separator"></span>
        <span class="position-relative text-break">{{
          competition?.format ? competition?.format : "N/A"
        }}</span>
      </div>
      <div class="d-flex gap-2 align-items-center">
        <img
          src="../../../assets/images/explore-page/calendar.png"
          class="img-fluid"
          alt="calendar"
        />
        <span>
          <span class="fw-bold">Prelims: </span>
          <span class="text-break">{{
            competition?.prelims
              ? (competition.prelims | date : "MMM d, y")
              : "N/A"
          }}</span>
        </span>
        <span class="">
          <span class="fw-bold">Finals: </span>
          <span class="text-break">{{
            competition?.final ? (competition.final | date : "MMM d, y") : "N/A"
          }}</span>
        </span>
      </div>
      <!-- End: Info -->

      <!-- Footer -->
      <footer class="d-flex align-items-center gap-2 mt-auto pt-3">
        <button
          class="btn btn-primary"
          *ngIf="competition?.status !== 'Finished'"
          [disabled]="
            competition?.isUpcoming ||
            (!isUserLoggedIn && competition?.registration_stage == 'Closed')
          "
          (click)="storeCompetition(competition)"
        >
          Join Now
        </button>
        <button
          type="button"
          class="btn btn-view-details link-dark link-underline-opacity-100-hover d-flex justify-content-center align-items-center"
          (click)="navigatetoDetails(competition?.details)"
        >
          View Details
        </button>
      </footer>
      <!-- End: Footer -->
    </div>
  </div>
</div>
