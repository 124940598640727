<div
  class="d-flex justify-content-between flex-column flex-md-row gap-3 p-2 mb-3"
>
  <div class="d-flex align-content-center gap-3">
    <app-search
      #search
      class="dynamix-width-dropdown"
      [searchMaxWidth]="'250px'"
      [placeholder]="'Search Questions'"
      [searchFunction]="onSearch.bind(this)"
      (searchResults)="handleSearchResults($event)"
    ></app-search>
    <div style="width: 280px">
      <p-dropdown
        [options]="['High School (Year 10-13)', 'Middle School (Year 7-9)']"
        [(ngModel)]="category"
        [appendTo]="'body'"
        placeholder="Team Category"
      ></p-dropdown>
    </div>
  </div>
  <button type="button" class="btn btn-primary" (click)="saveCategory()">
    Save
  </button>
</div>
<p-table
  [value]="teamMemberList"
  [scrollable]="true"
  responsiveLayout="stack"
  [totalRecords]="totalRecords"
  [breakpoint]="'768px'"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>School</th>
      <th>Registration Round</th>
      <th>Paid</th>
      <th class="text-center">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-member>
    <tr>
      <td>{{ member?.name ?? "-" }}</td>
      <td>{{ member.email }}</td>
      <td>{{ schoolAndCategory.school ?? "-" }}</td>
      <td>{{ registrationStage ?? "-" }}</td>
      <!-- <td>{{ member.amountPaid }}</td> -->
      <td>
        <span
          class="badge dot"
          [ngClass]="{
            'badge-primary': member.payment_status == 'Paid',
            'badge-success': member.payment_status == 'Waived',
            'badge-warning': member.payment_status == 'Pending',
        }"
          >{{ member.payment_status }}</span
        >
      </td>
      <td class="text-center" style="width: 180px">
        <!-- uncomment condition wise -->
        <div class="w-100 d-flex justify-content-between gap-2">
          <button class="btn btn-danger" (click)="removeMember(member)">Remove</button>
          <p-button
            [disabled]="
              member.payment_status == 'Waived' ||
              member.payment_status == 'Paid'
            "
            class="custom-ternary p-button-remove"
            (onClick)="openConfirmPopup(member)"
            >{{
              member.payment_status == "Waived" ? "Waived" : "Waive"
            }}</p-button
          >
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">No records found</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  [(visible)]="popVisible"
  [modal]="true"
  [style]="{ width: window.innerWidth > 768 ? '75vw' : '95vw', height: '50vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="h-100 d-flex flex-column gap-3 justify-content-between">
    <div>
      <div class="text-center">
        <h3>Please confirm you want to waive for</h3>
      </div>
      <div class="d-flex flex-column align-content-center">
        <h4 class="text-center">{{ selectedMember?.name }}</h4>
        <span class="text-center">{{ selectedMember?.email }}</span>
      </div>
    </div>
    <div class="proceed-button">
      <button type="button" class="btn btn-primary" (click)="waiveMember()">
        Yes, Waive
      </button>
    </div>
  </div>
</p-dialog>
