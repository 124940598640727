import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Competitions } from 'src/app/student-competitions/models/competions.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-explore-page-cards',
  templateUrl: './explore-page-cards.component.html',
  styleUrls: ['./explore-page-cards.component.scss'],
})
export class ExplorePageCardsComponent {
  @Input() competitions: Competitions[];
  @Input() isRegisterationOpen: boolean;

  imageBaseURL: string = environment.apiUrl;
  isUserLoggedIn: boolean;
  isValidToken: boolean = false;
  registrationStageImages: { [key: string]: string } = {
    Regular: '../../../assets/images/explore-page/reg-timer.svg',
    Late: '../../../assets/images/explore-page/late-timer.svg',
    Priority: '../../../assets/images/explore-page/priority-timer.svg',
  };

  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit(): void {
    const token = localStorage.getItem('token') ?? '';
    if (token) {
      this.apiService.verifyAuth(token).subscribe(
        (res) => {
          if (res?.success) {
            this.isValidToken = true;
          } else {
            this.isValidToken = false;
          }
        },
        (err) => {
          this.isValidToken = false;
        }
      );
    } else {
      this.isValidToken = false;
    }
    this.isUserLoggedIn = this.isValidToken ? true : false;
  }

  storeCompetition(competition: Competitions) {
    localStorage.setItem('comeptitionDetails', JSON.stringify(competition));
    if (!this.isUserLoggedIn) {
      if (competition.registration_stage === 'Closed') {
        return;
      }
      this.router.navigate(['/competition'], {
        queryParams: { competitionId: competition.id },
      });
    }
  }
  navigatetoDetails(link: string) {
    window.open(link, '_blank');
  }
}
