import { Component } from '@angular/core';
import { CommonServiceService } from '../../common-service.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TeamDetail } from 'src/app/student-competitions/models/competions.models';
import { of } from 'rxjs';

@Component({
  selector: 'app-team-member-list',
  templateUrl: './team-member-list.component.html',
  styleUrls: ['./team-member-list.component.scss'],
})
export class TeamMemberListComponent {
  public teamMemberList: TeamDetail[] = [];
  tempList: TeamDetail[] = [];
  teamDetails: any;
  popVisible: boolean;
  selectedTeam: any;
  teamID: any;
  selectedMember: any;
  registrationStage: string;
  totalRecords: number;
  first: number = 0;
  category = null;
  schoolAndCategory = {
    category: null,
    school: null,
  };
  window = window;

  constructor(
    private commonService: CommonServiceService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.selectedTeam = this.config.data?.selectedTeam;
    this.teamID = this.config.data?.selectedTeam?.id;
    console.log(this.selectedTeam);
    this.registrationStage = this.config.data?.registrationStage;
    this.getTeamMembers();
  }

  onSearch(searchText: string) {
    if (!searchText) {
      this.first = 0;
      this.getTeamMembers();
    }
    const genericString = searchText.toLowerCase();
    return of(
      this.teamMemberList.filter((x) => {
        return (
          x?.name.toLowerCase().includes(genericString) ||
          x?.email.toLowerCase().includes(genericString)
        );
      })
    );
  }

  removeMember(removeMember) {
    this.commonService
      .updateRecord(
        `admin/competitions/teams/removemember/${removeMember.team_id}`,
        {
          isCategorList: true,
          name: {
            member_id: removeMember.id,
          },
        }
      )
      .subscribe(
        (res) => {
          this.commonService.showToast(
            'Member removed successfully',
            'success',
            1000
          );
          this.getTeamMembers();
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Please try again later',
            'error',
            1000
          );
        }
      );
  }

  saveCategory() {
    if (!this.category) {
      this.commonService.showToast('Please select category', 'error', 1000);
      return;
    }
    const finalObj = {
      isCategorList: true,
      name: {
        name: this.teamDetails.name,
        school: this.teamDetails.school,
        category: this.category,
      },
    };
    this.commonService
      .saveRecord(`admin/competitions/teams/${this.teamID}`, finalObj)
      .subscribe(
        (res) => {
          this.commonService.showToast(
            'Category saved successfully',
            'success',
            1000
          );
          this.ref.close();
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            1000
          );
        }
      );
  }

  handleSearchResults(searchResults: any) {
    this.totalRecords = searchResults.total;
    if (searchResults && searchResults.length) {
      this.teamMemberList = searchResults;
      this.tempList = searchResults;
      this.totalRecords = this.tempList.length;
    } else {
      this.first = 0;
      this.totalRecords = 0;
      this.teamMemberList = [];
    }
  }

  getTeamMembers() {
    this.commonService
      .getByIdDetail('admin/competitions/teams/', this.teamID)
      .subscribe(
        (res) => {
          this.teamMemberList = res?.data && res.data?.teams?.members;
          this.tempList = res?.data && res.data?.teams?.members;
          this.teamDetails = res?.data && res.data?.teams;
          this.schoolAndCategory = {
            category: res?.data && res.data?.teams?.category,
            school: res?.data && res.data?.teams?.school,
          };
          this.category = res?.data && res.data?.teams?.category;
        },
        (error) => {
          this.ref.close();
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            1000
          );
        }
      );
  }

  openConfirmPopup(member: any) {
    this.selectedMember = member;
    this.popVisible = true;
  }

  waiveMember() {
    this.commonService
      .updateRecord(`admin/competitions/waive/${this.selectedMember.id}`, {})
      .subscribe(
        (res) => {
          this.commonService.showToast(
            'Member Waived successfully',
            'success',
            3000
          );
          this.popVisible = false;
          this.getTeamMembers();
        },
        (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            1000
          );
        }
      );
  }
}
